<template>
  <div class="account">
    <div class="column account-center">
      <div class="row-center text-main accent-title">
        <div
          v-for="(item, index) in tabbar"
          :key="index"
          class="pointer"
          :class="tabbarIndex == index ? 'tabClass' : ''"
          @click="tabClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <!-- 企业认证 -->
      <div
        class="account-setp"
        :class="submitShow == false ? 'account-termination' : ''"
        v-if="tabbarIndex == 0"
      >
        <div v-if="entDetail.is_auth !== 1 && subNum !== 0">
          <div class="setp-card_no" v-if="!formShow && userDetail.is_auth == 0">
            <div class="card_no-title">请输入你的信息</div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="姓名" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="card_no">
                <el-input v-model="ruleForm.card_no"></el-input>
              </el-form-item>
              <el-form-item style="display: flex; justify-content: flex-end">
                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="formShow">
            <div class="flex-center setp-title">
              <div class="text-main">开启高效的招聘方式，你只需要2步：</div>
              <div class="bg-blue bg-num text-white font-lighter position-relative">
                1
                <div class="position-absolute text-blue-rz">个人认证</div>
              </div>
              <div class="set-border position-relative">
                <div
                  class="position-absolute top-0 left-0 bg-blue"
                  :class="hereShow == true ? 'heard-bord' : ''"
                ></div>
              </div>
              <div
                class="set-bg-num bg-num text-white font-lighter position-relative"
                :class="hereShow == true ? 'heard-num-two' : ''"
              >
                2
                <div
                  class="position-absolute"
                  :class="hereShow == true ? 'heard-two' : ''"
                >
                  企业认证
                </div>
              </div>
            </div>
            <div class="setp-center direction" v-if="hereShow == false">
              <div v-if="userDetail.is_auth == 0" class="direction">
                <div class="text-main font-bold">请使用支付扫码完成实名认证</div>
                <img :src="qrcode_url" alt="" />
              </div>
              <img
                :src="userDetail.avatar_url ? userDetail.avatar_url : avatar_url"
                alt=""
                v-else
              />
              <div class="font-normal pointer" @click="hereShowClick">
                已完成认证，请 <span class="text-blue">点击这里</span>
              </div>
            </div>
            <div class="setp-center-one" v-else>
              <div class="text-main font-light">
                您可以通过以下方式，认证您与当前企业的关系
              </div>
              <div class="row-center img-text">
                <div class="font-lighter flex-center text-blue">【营业执照】照片</div>
                <span class="text-main font-lighter"
                  >营业执照需是在有效期范围内的原件照片，扫描或者加盖公章的复印件</span
                >
              </div>
              <div class="row">
                <div class="row">
                  <div
                    class="img-list-item"
                    v-for="(item, index) in imageUrlList"
                    :key="index"
                    @mouseenter="mouseenterImg(index)"
                    @mouseleave="mouseleaveImg"
                  >
                    <img :src="item" alt="" />
                    <div
                      class="list-posi"
                      v-if="imgIndex == index"
                      @click="deleteImgClick(index)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
                <div v-if="imageUrlList.length < 3">
                  <el-upload
                    action=""
                    list-type="picture-card"
                    :show-file-list="false"
                    accept=".jpg,.png"
                    :http-request="uploadMainImg"
                    :limit="3"
                  >
                    <i class="el-icon-plus">
                      <div class="el-upload__text">点击上传图片</div>
                    </i>
                  </el-upload>
                </div>
              </div>

              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
              <div class="submit-class row-end">
                <div
                  class="text-white bg-blue flex-center pointer"
                  :class="statusShow ? 'status-class' : ''"
                  @click="submitClick"
                >
                  确认
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 企业认证完成审核 -->
        <div v-if="entDetail.is_auth == 1" class="direction text-main bzdxs">
          <div class="font-weight-bolder">已完成认证</div>
        </div>
        <!-- 企业认证正在审核中 -->
        <div v-if="subNum === 0" class="direction text-main bzdxs">
          <div class="font-weight-bolder">提交成功！</div>
          <div class="font-lighter">我们将在1-2个工作日内进行审核，请您耐心等待。</div>
        </div>
      </div>
      <!-- 设置打招呼语 -->
      <div class="account-call" v-if="tabbarIndex == 1">
        <div v-if="customizeShow">
          <div class="title text-main">打招呼语</div>
          <div class="customize row-end">
            <div
              class="text-white bg-blue font-lighter flex-center pointer"
              @click="customizeShow = false"
            >
              自定义招呼语
            </div>
          </div>
          <div class="font-normal text-main">
            向人才发起聊天时，会自动发送您所设置的招呼语
          </div>
          <div
            class="customize-item"
            v-infinite-scroll="loadClick"
            :infinite-scroll-immediate="false"
          >
            <div
              class="item space-between font-normal"
              v-for="item in customizeList"
              :key="item.id"
            >
              <span>{{ item.content }}</span>
              <span
                class="text-blue pointer"
                :class="item.is_default == 1 ? 'customizeText' : ''"
                @click="checkClick(item)"
                >{{ item.is_default == 1 ? "默认使用" : "设为默认" }}</span
              >
            </div>
          </div>
        </div>
        <div v-else>
          <div class="title text-main">自定义打招呼语</div>
          <div class="font-normal text-main custom-text">
            向人才发起聊天时，会自动发送您所设置的招呼语
          </div>
          <div class="custom-input">
            <el-input
              type="textarea"
              placeholder="请输入打招呼语"
              v-model="sqyQuery.content"
              maxlength="100"
              show-word-limit
            >
            </el-input>
            <div class="row-end input-submit">
              <div
                class="text-white font-normal flex-center pointer bg-blue"
                @click="customClick"
              >
                保存
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--地址管理 -->
      <div class="address" v-if="tabbarIndex == 2">
        <div class="title text-main">{{ entDetail.ent_name }}</div>
        <div
          class="address-add flex-center text-white bg-blue font-normal pointer"
          @click="addClick"
        >
          添加地址
        </div>
        <div class="address-center text-main space-between">
          <span>地址</span>
          <span>操作</span>
        </div>
        <div class="address-item">
          <div
            class="item space-between"
            v-infinite-scroll="loadAddress"
            infinite-scroll-disabled="disabled"
            v-for="item in addressCount"
            :key="item.id"
            :class="last_page > 10 ? 'item-count' : ''"
          >
            <span class="text-main font-normal"
              >{{ item.province }}{{ item.city }}{{ item.address }}</span
            >
            <div class="row text-blue font-normal">
              <span class="pointer" @click="editAddress(item)">编辑</span>
              <span
                class="pointer"
                @click="deleteAddress(item.id)"
                v-if="addressCount.length > 1"
                >删除</span
              >
            </div>
          </div>
          <p v-if="loading && addressCount.length > 0">加载中...</p>
          <p v-if="noMore && addressCount.length > 0">没有更多了</p>
          <p v-if="addressCount.length == 0" class="p-div">暂无地址</p>
        </div>
      </div>
      <!-- 企业主页 -->
      <div class="enterprise-home" v-if="tabbarIndex == 3">
        <div class="title text-main">
          <span>*</span>
          <span>公司基本信息</span>
        </div>
        <div class="text-mian font-normal enterprise-introduce">
          丰富的公司介绍，能获得更多求职者的青睐，为你的职位带来更多查看与沟通
        </div>
        <div class="text-main font-normal">企业logo:</div>
        <div class="logo-upload">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            accept=".jpg,.png,.jpeg"
            :http-request="uploadMainImgOne"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon">
              <div class="el-upload__text">上传文件</div>
            </i>
          </el-upload>
        </div>
        <div class="enterprise-select">
          <div class="row select-center">
            <div class="select-left">
              <div class="text-main font-normal select-title">企业全称：</div>
              <el-input
                placeholder="请输入企业名称"
                v-model="queryOne.ent_name"
              ></el-input>
            </div>
            <div class="select-left">
              <div class="text-main font-normal select-title">企业简称：</div>
              <el-input
                placeholder="请输入企业简称"
                v-model="queryOne.short_name"
              ></el-input>
            </div>
          </div>
          <div class="row select-center">
            <div class="select-left">
              <div class="text-main font-normal select-title">企业性质:</div>
              <el-select v-model="queryOne.nature" placeholder="请选择">
                <el-option
                  v-for="item in nature"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="select-left">
              <div class="text-main font-normal select-title">所属行业:</div>
              <el-select v-model="queryOne.industry" placeholder="请选择">
                <el-option
                  v-for="item in industry"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row select-center">
            <div class="select-left">
              <div class="text-main font-normal select-title">人员规模:</div>
              <el-select v-model="queryOne.scale" placeholder="请选择">
                <el-option
                  v-for="item in scale"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="font-lighter enterprise-text">企业介绍</div>
        <div class="text-main font-normal">
          可以简单介绍一下公司的发展状况，服务领域、主要产品信息
        </div>
        <div class="enterprise-textarea">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="queryOne.introduction"
          >
          </el-input>
        </div>
        <div class="font-lighter enterprise-text">企业福利</div>
        <div class="text-main font-normal">添加公司福利，提升公司吸引力</div>
        <div
          class="pointer bg-blue text-white font-normal add-welfare flex-center"
          @click="welfareClick"
        >
          添加
        </div>
        <div class="row-center welfare-center">
          <div
            class="text-blue font-normal pointer"
            v-for="(item, index) in queryOne.welfare"
            :key="index"
            @mouseenter="welfareIndex = index"
            @mouseleave="welfareIndex = -1"
          >
            <span class="flex-center">{{ item }}</span>
            <div
              class="posi-del flex-center"
              v-if="welfareIndex == index"
              @click="delClick(index)"
            >
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
        <div class="font-lighter enterprise-text">企业风采</div>
        <div class="logo-upload row">
          <div class="row">
            <div
              class="el-logo-style"
              v-for="(item, index) in styleImgList"
              :key="index"
              @mouseenter="mouseenterImg(index)"
              @mouseleave="mouseleaveImg"
            >
              <img :src="item" alt="" />
              <div
                class="list-posi-style"
                v-if="imgIndex == index"
                @click="deleteImgClickOne(index)"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
          <div v-if="styleImgList.length < 6">
            <el-upload
              class="avatar-uploader"
              action=""
              accept=".jpg,.png,.jpeg"
              :show-file-list="false"
              :http-request="uploadMainImgTwo"
            >
              <i class="el-icon-plus avatar-uploader-icon">
                <div class="el-upload__text">上传文件</div>
              </i>
            </el-upload>
          </div>
        </div>
        <div class="row-end enterprise-botton">
          <!-- <div class="bg-blue text-white font-normal flex-center">预览</div> -->
          <div
            class="bg-blue text-white font-normal flex-center pointer"
            @click="saveClick"
          >
            保存
          </div>
        </div>
      </div>
    </div>
    <company-address
      ref="address"
      :title="titleAddress"
      @hide="getAddressClick"
      :itemAddress="itemAddress"
    ></company-address>
    <mask-model ref="mask" width="460px" title="添加福利" @submitClick="submitInputClick">
      <div class="mask-input">
        <el-input
          type="text"
          placeholder="请输入福利4字以内"
          v-model="welfare"
          :maxlength="4"
        ></el-input>
      </div>
    </mask-model>
  </div>
</template>
<script>
import maskModel from "../../../components/mask.vue";
import companyAddress from "../../../components/companyAddress.vue";
import api from "../../../api/address";
import toolApi from "../../../api/tool";
import recruiterApi from "../../../api/recruiter";
import apiUser from "../../../api/user";
import enterprisesApi from "../../../api/enterprises";
import { mapState } from "vuex";
export default {
  components: { companyAddress, maskModel },
  data() {
    var checkCard = (rule, value, callback) => {
      if (!this.$util.checkStr(value, "card")) {
        return callback(new Error("请输入正确的身份证号"));
      }
    };
    return {
      tabbar: ["企业认证", "设置打招呼语", "工作地址管理", "企业主页"],
      tabbarIndex: 0,
      hereShow: false,
      dialogImageUrl: "",
      dialogVisible: false,
      submitShow: true,
      customizeList: [],
      // 是否显示自定义招呼语页面
      customizeShow: true,
      textarea: "",
      loading: false,
      titleAddress: "添加地址",
      // logo
      imageUrl: "",
      // 地址相关的数据
      query: {
        pageSize: 12,
        page: 1,
      },
      addressShow: true,
      addressCount: [],
      last_page: 1,
      itemAddress: {},
      // 企业认证相关的数据
      imageUrlList: [],
      imgIndex: -1,
      // 企业认证的id
      license: [],
      statusShow: false,

      // 设置企业主页相关的数据
      queryOne: {
        logo: "", //企业logo
        ent_name: "", //企业名称
        short_name: "", //企业简称
        nature: "", //企业性质
        scale: "", //企业规模
        industry: "", //所属行业
        welfare: [], //企业福利
        file_ids: [], //企业风采
        introduction: "", //企业介绍
        province: "",
        province_id: "",
        city_id: "",
        city: "",
        address: "",
      },
      // 企业风采图片
      styleImgList: [],
      // 企业福利
      welfare: "",
      industry: [],
      nature: [],
      scale: [],
      welfareIndex: -1,
      // 设置打招呼语
      sqyQuery: {
        content: "",
        id: "",
        is_default: 0,
        type: 1,
      },
      ruleForm: {
        name: "",
        card_no: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        card_no: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
      },
      formShow: false,
      subNum: null,
      qrcode_url: "",
      setTime: null,
      avatar_url: require("@/assets/login/922028.png"),
    };
  },

  created() {
    if (this.entDetail.is_auth !== 1) {
      this.getEnterprise();
    }
    if (this.userDetail.is_auth == 1) {
      this.formShow = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.setTime);
  },
  mounted() {},
  computed: {
    noMore() {
      return this.query.page >= this.last_page;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    // 2. 解构状态作为计算属性
    ...mapState(["entDetail", "userDetail"]),
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      apiUser.userDetail().then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
    hereShowClick() {
      if (this.userDetail.is_auth == 0) {
        this.$util.msg("你还未进行个人认证", "warning");
        return;
      }
      this.hereShow = true;
    },
    // 单独查询支付宝认证
    queryCertify() {
      this.setTime = setInterval(() => {
        apiUser.queryCertify().then((res) => {
          if (res.code == 200) {
            if (res.data.status == 1) {
              clearInterval(this.setTime);
              this.$util.msg(res.msg);
              this.getUserInfo();
              this.formShow = true;
            }
          }
        });
      }, 1000);
    },

    getUserCertify() {
      apiUser.getUserCertify(this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.qrcode_url = res.data.qrcode_url;
          this.formShow = true;
          this.queryCertify();
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // console.log(valid);
        if (valid) {
          this.getUserCertify();
        }
      });
    },
    // 获取企业福利
    getWelfare() {
      toolApi.getWelfare().then((res) => {
        if (res.code == 200 && res.data.length > 0) {
          let welfare = res.data.map((item) => {
            return item.welfare_name;
          });
          this.queryOne.welfare = welfare;
        }
      });
    },
    // 删除某一个企业福利
    delClick(index) {
      this.queryOne.welfare.splice(index, 1);
    },
    // 获取打招呼语
    getSay() {
      this.customizeList = [];
      recruiterApi.getSay({pageSize:100}).then((res) => {
        this.customizeList = res.data.data;
      });
    },
    // 设置打招呼语
    setSay() {
      recruiterApi.setSay(this.sqyQuery).then((res) => {
        if (res.code == 200) {
          this.sqyQuery.content = "";
          this.$util.msg(res.msg);
          this.getSay();
        }
      });
    },
    // 获取企业详情
    enterpriseDetail(index) {
      enterprisesApi.releasePosition({ is_mine: 1 }).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.queryOne.ent_name = res.data.ent_name;
            this.queryOne.short_name = res.data.short_name;
            this.queryOne.welfare = res.data.welfare;
            this.imageUrl = res.data.logo_url;
            this.queryOne.logo = res.data.logo;
            this.queryOne.introduction = res.data.introduction;
            this.queryOne.city_id = res.data.city_id;
            this.queryOne.city = res.data.city;
            this.queryOne.address = res.data.address;
            this.queryOne.province_id = res.data.province_id;
            this.queryOne.province = res.data.province;
            if (res.data.other_info) {
              this.queryOne.scale = res.data.other_info.scale.value;
              this.queryOne.nature = res.data.other_info.nature.value;
              this.queryOne.industry = res.data.other_info.industry.value;
            }
            if (res.data.styles.length > 0) {
              this.queryOne.file_ids = res.data.styles.map((item) => {
                return item.file_id;
              });
              this.styleImgList = res.data.styles.map((item) => {
                return item.file_path;
              });
            }
          } else {
            this.getWelfare();
          }

          if (index == 0) {
            this.$store.commit("entDetail", res.data);
          }
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      apiUser.userDetail().then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
    // 保存企业信息
    saveClick() {
      if (!this.yzgz()) return;
      const loading = this.$loading({
        lock: true,
        text: "提交中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      recruiterApi
        .recruiter(this.queryOne)
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            this.$util.msg(res.msg);
            this.getUserInfo();
            this.enterpriseDetail(0);
          }
        })
        .catch((err) => {
          loading.close();
        });
    },
    // 验证规则
    yzgz() {
      if (this.queryOne.ent_name == "") {
        this.$util.msg("请填写企业名称", "error");
        return false;
      } else if (this.queryOne.nature == "") {
        this.$util.msg("请选择企业性质", "error");
        return false;
      } else if (this.queryOne.scale == "") {
        this.$util.msg("请选择企业规模", "error");
        return false;
      } else if (this.queryOne.industry == "") {
        this.$util.msg("请选择所属行业", "error");
        return false;
      } else {
        return true;
      }
    },
    // 添加企业福利
    submitInputClick() {
      if (this.welfare == "") {
        this.$util.msg("请输入福利内容", "warning");
        return;
      }
      this.queryOne.welfare.push(this.welfare);
      this.welfare = "";
    },
    // 获取列表数据
    getList() {
      toolApi.getUserConfig().then((res) => {
        if (res.code == 200) {
          this.industry = res.data.industry.options;
          this.nature = res.data.nature.options;
          this.scale = res.data.scale.options;
        }
      });
    },
    // 获取企业认证状态
    getEnterprise() {
      recruiterApi.getEnterprise().then((res) => {
        if (res.data.length !== 0) {
          this.subNum = res.data.status;
          if (res.data.status == 0) {
            this.submitShow = false;
          } else {
            let imageUrlList = res.data.license.map((item) => {
              return item.file_path;
            });
            let file_ids = res.data.license.map((item) => {
              return item.file_id;
            });
            this.license = file_ids;
            this.imageUrlList = imageUrlList;
          }
          // 判断企业认证是不是审核通过
          if (res.data.status == 1) {
            this.statusShow = true;
          }
        } else {
        }
      });
    },
    // 提交企业认证
    submitClick() {
      if (this.license.length == 0) {
        this.$util.msg("请上传营业执照", "warning");
        return;
      }
      recruiterApi.enterprise({ license: this.license }).then((res) => {
        if (res.code == 200) {
          this.getEnterprise();
          this.$util.msg(res.msg);
        }
      });
    },
    // 移除照片
    deleteImgClick(index) {
      this.imageUrlList.splice(index, 1);
      this.license.splice(index, 1);
    },
    deleteImgClickOne(index) {
      this.styleImgList.splice(index, 1);
      this.queryOne.file_ids.splice(index, 1);
    },
    //鼠标移除企业认证照片
    mouseleaveImg() {
      this.imgIndex = -1;
    },
    // 鼠标移入到企业认证照片
    mouseenterImg(index) {
      this.imgIndex = index;
    },
    // 关闭弹窗调用地址刷新数据
    getAddressClick() {
      this.addressShow = true;
      this.query.page = 1;
      this.getAddress();
    },
    // 获取地址
    getAddress() {
      api.getAddress(this.query).then((res) => {
        this.last_page = res.data.last_page;
        if (this.addressShow) {
          this.addressCount = res.data.data;
        } else {
          this.loading = false;
          this.addressCount = this.addressCount.concat(res.data.data);
        }
      });
    },
    // 添加福利
    welfareClick() {
      this.$refs.mask.show();
    },
    // 上传logo
    uploadMainImgOne(file) {
      if (
        file.file.type != "image/png" &&
        file.file.type != "image/jpg" &&
        file.file.type != "image/jpeg"
      ) {
        this.$util.msg("请上传png,jpg,jpeg格式的图片", "error");
        return;
      }
      const isLt2M = file.file.size / 1024 / 1024 < 6;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      toolApi
        .uploadImg(data)
        .then((res) => {
          if (res.code == 200) {
            this.$util.msg(res.msg);
            this.queryOne.logo = res.data.file_id;
            this.imageUrl = res.data.file_path;
          }
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        });
    },
    // 删除地址
    deleteAddress(id) {
      this.$confirm("此操作将永久删除该地址, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.delAddress({ id }).then((res) => {
          if (res.code == 200) {
            this.$util.msg("删除成功！");
            this.getAddressClick();
          }
        });
      });
    },
    // 编辑地址
    editAddress(item) {
      this.itemAddress = item;
      this.titleAddress = "编辑地址";
      this.$refs.address.show();
    },
    // 添加地址
    addClick() {
      this.titleAddress = "添加地址";
      this.$refs.address.show();
    },
    // 加载地址
    loadAddress() {
      if (this.query.page < this.last_page) {
        this.loading = true;
        setTimeout(() => {
          this.query.page += 1;
          this.addressShow = false;
          this.getAddress();
        }, 1000);
      } else {
        this.loading = false;
      }
    },
    // 保存打招呼语
    customClick() {
      if (this.sqyQuery.content.trim() == "") {
        this.$util.msg("请输入打招呼语");
        return;
      }
      this.$confirm("将保存自定义招呼语, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.customizeShow = true;
        this.setSay();
      });
    },
    // 设为默认
    checkClick(item) {
      if (item.is_default == 1) return;
      this.$confirm("设置为默认招呼语, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.sqyQuery = item;
        this.sqyQuery.is_default = 1;
        this.setSay();
      });
    },
    // 打招呼无限滚动
    loadClick() {},
    tabClick(index) {
      this.tabbarIndex = index;
      if (index == 2) {
        this.addressShow = true;
        this.query.page = 1;
        this.getAddress();
      } else if (index == 3) {
        this.getList();
        this.enterpriseDetail();
      } else if (index == 1) {
        this.getSay();
      } else {
        this.getEnterprise();
      }
    },
    // 上传图片
    uploadMainImg(file) {
       if(file.file.type!="image/png" &&  file.file.type!="image/jpg" && file.file.type!="image/jpeg"){
        this.$util.msg('请上传png,jpg,jpeg格式的图片','error')
        return
      }
      const isLt2M = file.file.size / 1024 / 1024 < 6;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      toolApi
        .uploadImg(data)
        .then((res) => {
          this.$util.msg(res.msg);
          this.dialogImageUrl = res.data.file_path;
          this.dialogVisible = true;
          this.imageUrlList.push(res.data.file_path);
          this.license.push(res.data.file_id);
        })
        .catch((err) => {});
    },
    // 上传企业风采
    uploadMainImgTwo(file) {
      if (
        file.file.type != "image/png" &&
        file.file.type != "image/jpg" &&
        file.file.type != "image/jpeg"
      ) {
        this.$util.msg("请上传png,jpg,jpeg格式的图片", "error");
        return;
      }
      const isLt2M = file.file.size / 1024 / 1024 < 6;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      toolApi
        .uploadImg(data)
        .then((res) => {
          this.$util.msg(res.msg);
          this.styleImgList.push(res.data.file_path);
          this.queryOne.file_ids.push(res.data.file_id);
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="less" scoped>
.account {
  width: 100%;
  padding: 0 9.1875rem 2.125rem 9.1875rem;
  .account-center {
    width: 100%;
    .accent-title {
      font-size: 20px;
      height: 73px;
      div {
        margin-right: 37px;
      }
      .tabClass {
        color: #126bf9;
        font-size: 1.5rem;
      }
    }
    .account-termination {
      padding-top: 0 !important;
    }
    // 企业认证
    .account-setp {
      width: 100%;
      height: 50rem;
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
      padding-top: 6.25rem;
      .setp-card_no {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .card_no-title {
          font-size: 24px;
          margin-bottom: 20px;
        }
        /deep/.el-input {
          width: 260px;
        }
        /deep/.el-form-item {
          margin-bottom: 40px;
        }
      }
      .bzdxs {
        width: 100%;
        height: 100%;
        font-size: 20px;
        justify-content: center;
        div:first-child {
          margin-bottom: 1.875rem;
        }
      }
      .setp-title {
        margin-bottom: 4.375rem;
        > div:first-child {
          font-size: 20px;
          margin-right: 3.125rem;
        }
        .bg-num {
          border-radius: 50%;
          width: 31px;
          height: 31px;
          text-align: center;
          line-height: 31px;
          div {
            width: 80px;
            top: 36px;
            left: -24px;
            color: #a0a2ae;
          }
          .text-blue-rz {
            color: #126bf9;
          }
          .heard-two {
            color: #126bf9;
          }
        }
        .set-bg-num {
          background: #a0a2ae;
          .heard-num-two {
            background: #126bf9;
          }
        }
        .heard-num-two {
          background: #126bf9;
        }
        .set-border {
          width: 22.625rem;
          border-bottom: 1px solid #e6e6ee;
          margin: 0 10px;
          div {
            width: 50%;
            border-bottom: 1px solid #126bf9;
          }
          .heard-bord {
            width: 100%;
          }
        }
      }
      .setp-center {
        width: 56.5625rem;
        height: 33.5rem;
        background: #ffffff;
        box-shadow: 0px -1px 16px 0px rgba(222, 222, 222, 0.37);
        margin: 0 auto;
        justify-content: center;
        img {
          width: 12.0625rem;
          height: 12.0625rem;
          margin: 1.875rem 0 1.25rem 0;
        }
        > div:last-child {
          color: #8d92a1;
        }
      }
      .setp-center-one {
        width: 56.5625rem;
        height: 33.5rem;
        background: #ffffff;
        box-shadow: 0px -1px 16px 0px rgba(222, 222, 222, 0.37);
        margin: 0 auto;
        padding: 3.1875rem 3.3125rem 4.875rem 3.25rem;
        .img-list-item {
          width: 148px;
          height: 148px;
          position: relative;
          margin-right: 10px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
          .list-posi {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.5);
            margin-right: 10px;
            color: #ffffff;
          }
        }
        .img-text {
          margin-top: 1.375rem;
          margin-bottom: 4.375rem;
          div {
            width: 153px;
            height: 43px;
            background: #e1edff;
            border-radius: 5px;
            margin-right: 10px;
          }
        }
        /deep/.el-upload--picture-card {
          line-height: 170px;
        }

        .el-upload__text {
          font-size: 12px;
          margin-top: 10px;
        }
        .submit-class {
          margin-top: 2.875rem;
          div {
            width: 140px;
            height: 43px;
          }
          .status-class {
            opacity: 0.5;
          }
        }
      }
    }
    // 打招呼语
    .account-call {
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
      width: 100%;
      height: 50rem;
      padding: 2.5rem 2.3125rem 0 2.4375rem;
      .title {
        font-size: 20px;
      }
      .customize {
        div {
          width: 7.875rem;
          height: 2.25rem;
          border-radius: 5px;
        }
      }
      .customize-item {
        width: 100%;
        height: calc(50rem - 8.75rem);
        padding-top: 1.875rem;
        overflow: auto;
        .item {
          width: 100%;
          height: 3.375rem;
          background: #fafafb;
          color: #5a646f;
          padding-left: 1.4375rem;
          padding-right: 2.5rem;
          margin-bottom: 0.8125rem;
        }
        .customizeText {
          color: #959ba2;
        }
      }
      .custom-text {
        margin-top: 1.1875rem;
        margin-bottom: 2.4375rem;
      }
      .custom-input {
        width: 100%;
        height: 219px;
        background: #fafafb;
        /deep/.el-textarea {
          width: 100%;
          height: 156px;
        }
        /deep/.el-textarea__inner {
          height: 100%;
          border: none;
          background: #fafafb;
          padding-top: 20px;
        }
        .input-submit {
          margin-top: 10px;
          div {
            width: 110px;
            height: 33px;
          }
        }
      }
    }
    // 地址管理
    .address {
      width: 100%;
      height: 50rem;
      padding: 2.4375rem 2.5rem 0 2.5rem;
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
      .title {
        font-size: 1.25rem;
      }
      .address-add {
        margin-top: 2.75rem;
        margin-bottom: 1.25rem;
        width: 7.375rem;
        height: 2.125rem;
      }
      .address-center {
        width: 100%;
        height: 60px;
        background: #fafafb;
        padding-left: 9.9375rem;
        padding-right: 10.125rem;
        font-size: 15px;
      }
      .address-item {
        width: 100%;
        overflow: auto;
        height: calc(50rem - 14.375rem);
        .item {
          width: 100%;
          height: 3.375rem;
          border-bottom: 1px solid #eeeeee;
          padding-left: 5.5625rem;
          padding-right: 8.5rem;
          div {
            span:first-child {
              margin-right: 1.75rem;
            }
          }
        }
        .item-count {
          padding-right: 7.5rem;
        }
        p {
          text-align: center;
          margin-top: 10px;
        }
        .p-div {
          width: 100%;
          height: 90%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    // 企业主页
    .enterprise-home {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
      padding: 2.4375rem 2rem 2.5rem 2.5rem;
      .title {
        font-size: 1.25rem;
        span:first-child {
          color: #f44f1c;
        }
      }
      .enterprise-introduce {
        margin-top: 1.125rem;
        margin-bottom: 2.5rem;
      }
      .logo-upload {
        margin-top: 0.625rem;
        margin-bottom: 2.0625rem;
        .el-logo-style {
          width: 4.3125rem;
          height: 4.3125rem;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .list-posi-style {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.5);
            margin-right: 10px;
            color: #ffffff;
          }
        }
        /deep/ .el-upload {
          border: 1px dashed #e3e7ed;
          border-radius: 2px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          width: 4.3125rem;
          height: 4.3125rem;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        }
        .el-upload__text {
          font-size: 12px;
          margin-top: 12px;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 0.75rem;
          color: #8c939d;
        }
        .avatar {
          width: 4.3125rem;
          height: 4.3125rem;
          display: block;
        }
      }
      .enterprise-select {
        margin-bottom: 2.5625rem;
        .select-center {
          margin-bottom: 1.9375rem;
          .select-left {
            margin-right: 8.6875rem;
            .select-title {
              margin-bottom: 0.6875rem;
            }
            /deep/.el-select {
              width: 315px;
            }
            /deep/.el-input {
              width: 315px;
            }
          }
        }
        .select-center:last-child {
          margin-bottom: 0;
        }
      }
      .enterprise-text {
        color: #495060;
        margin-top: 2.5rem;
        margin-bottom: 1.1875rem;
      }
      .enterprise-textarea {
        width: 100%;
        height: 13.6875rem;
        background: #fafafb;
        margin-top: 1.875rem;
        padding-right: 8px;
        /deep/.el-textarea {
          width: 100%;
          height: 100%;
        }
        /deep/.el-textarea__inner {
          width: 100%;
          height: 100%;
          border: none;
          background: #fafafb;
        }
      }
      .add-welfare {
        width: 6.875rem;
        height: 2.0625rem;
        margin-top: 30px;
      }
      .welfare-center {
        margin-bottom: 2.875rem;
        margin-top: 1.5625rem;
        > div {
          width: 6.875rem;
          height: 2.0625rem;
          margin-right: 1.25rem;
          position: relative;
          span {
            width: 100%;
            height: 100%;
            border: 1px solid #126bf9;
          }
          .posi-del {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            i {
              position: absolute;
              top: 4px;
              right: 6px;
            }
          }
        }
      }
      .enterprise-botton {
        div {
          width: 110px;
          height: 34px;
        }
        div:first-child {
          margin-right: 1.25rem;
        }
      }
    }
  }
  .mask-input {
    margin-top: 29px;
    margin-bottom: 1.25rem;
    width: 290px;
  }
}
</style>
